import homeStyles from './homepage.module.scss';
import Image from 'next/image';
import Head from 'next/head';
import { getSession, useSession } from 'next-auth/react';
import { getBedrockFavicon } from '@/utils/helpers';
import styles from './base/base.module.scss';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSelector } from '@/redux/user/selectors';
import { getUser } from '@/redux/user/actions';
import { PopupWithButton } from '@/components/wrappers/modal';
import SignupForm from '@/components/SignupFlow/SignupForm';
import PersonalInfoStep from '@/components/SignupFlow/PersonalInfoStep';
import SignupFlowHeader from '@/components/SignupFlow/SignupFlowHeader';
import WaitlistStep from '@/components/SignupFlow/WaitlistStep';
import { profileWebhook } from '@/redux/api';
import { Col, Row } from 'react-bootstrap';
import Link from 'next/link';

const NewLanding = ({ session }) => {
    const dispatch = useDispatch();
    const { status }: any = useSession();
    const { id: userId } = useSelector(getUserSelector);
    const [isModalOpen, setIsModalOpen] = useState(session != null);
    const [modalState, setModalState] = useState(session == null ? 'login' : 'personalInfo');
    const [email, setEmail] = useState('');
    const [pending, setPending] = useState(false);
    const router = useRouter();

    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.code === 'KeyA') {
                setIsModalOpen(true);
            }
        };
        window.addEventListener('keydown', onKeyDown);
        return () => window.removeEventListener('keydown', onKeyDown);
    }, []);

    useEffect(() => {
        if (router.query.waitlist) {
            setIsModalOpen(true);
            setModalState('waitlist');
        }
    }, []);

    useEffect(() => {
        if (session === null || status !== 'authenticated') return;
        if (!userId) {
            dispatch(getUser());
            return;
        }

        setEmail(session.personEntity?.email);

        if (modalState !== 'login') return;
        if (!isModalOpen) setIsModalOpen(router.query.modal === 'true');
    }, [status, userId]);

    const closeModal = () => setIsModalOpen(false);

    const onPersonalInfoNext: any = async (values) => {
        setPending(true);
        const res = await fetch('/api/createUserEntity', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...values, email }),
        }).finally(() => setPending(false));

        if (res.status === 500) {
            console.log('error', res);
            setModalState('login');
        } else {
            profileWebhook(email, 'home');
            setModalState('waitlist');
        }
    };

    const openModal = () => setIsModalOpen(true);

    const onEmailExists = (exists: boolean, email: string) => {
        setEmail(email);
        if (exists) {
            setModalState('waitlist');
        } else {
            setModalState('personalInfo');
        }
    };
    const resetModal = () => {
        setModalState('login');
        setEmail('');
    };
    return (
        <>
            <div className={homeStyles.body}>
                <Head>
                    <title>Bedrock Computer — Creative team space</title>
                    <link rel="icon" type="image/x-icon" href={getBedrockFavicon('disco-favicon', 'ico')} />
                    <link rel="apple-touch-icon" type="image/x-icon" href={getBedrockFavicon('disco-favicon', 'ico')} />
                    <meta property="og:site_name" content="Bedrock" />
                    <meta property="og:title" content="Bedrock" />
                    <meta
                        property="og:description"
                        content="A new web-enabled computer workspace for the modern age. Serene power, creative flow, and confident control."
                    />
                    <meta property="og:image" content="/images/socialMediaLinkImages/bedrockLinkUnfurl.png" />
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:title" content="Bedrock" />
                    <meta
                        property="twitter:description"
                        content="A new web-enabled computer workspace for the modern age. Serene power, creative flow, and confident control."
                    />
                    <meta property="twitter:image" content="/images/socialMediaLinkImages/bedrockLinkUnfurl.png" />
                    <link rel="preconnect" href="https://fonts.googleapis.com" />
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
                </Head>
                {/* <PopupWithButton
                    text=" "
                    className={homeStyles.hide}
                    forceOpen={openModal}
                    onClose={() => setOpenModal(false)}
                >
                    <SignupForm />
                </PopupWithButton> */}
                <PopupWithButton
                    text=""
                    className={styles.hide}
                    forceOpen={isModalOpen}
                    onClose={closeModal}
                    dontCloseOnBackdrop={modalState === 'personalInfo' || modalState === 'downloads'}
                >
                    {modalState === 'login' && (
                        <SignupForm
                            header="Welcome to Bedrock"
                            sub="All your data. Organized."
                            noRedirect
                            callbackUrl="/?modal=true"
                            isBaseApp
                            checkIfExists={onEmailExists}
                        />
                    )}
                    {modalState === 'personalInfo' && (
                        <PersonalInfoStep
                            onNext={onPersonalInfoNext}
                            entity={session?.personEntity}
                            email={email}
                            reset={resetModal}
                            pending={pending}
                        />
                    )}
                    {modalState === 'waitlist' && <WaitlistStep />}
                </PopupWithButton>
                <div className={homeStyles.mainWrapper}>
                    <SignupFlowHeader onGetStartedClick={openModal} entryPoint="home" />
                    <div className={homeStyles.heroWrapper}>
                        <div className={homeStyles.heroText}>
                            <div className={homeStyles.welcome}>
                                <Image
                                    src="/logos/bedrock-symbol-white.svg"
                                    alt="Bedrock Logo"
                                    width={30}
                                    height={30}
                                />
                                Welcome to Bedrock
                            </div>
                            <br />
                            <p>
                                We&apos;re building a personal computer that is web-integrated, Ai-augmented, and owned
                                by you.
                            </p>
                            <br />
                            <p>
                                Imagine a revolutionary new kind of work environment. You and your Ai, equipped with a
                                suite of modern tools designed to help you navigate the overwhelming chaos of modernity.
                                Serene power, creative flow, and confident control.
                            </p>
                            <br />
                            <p>The 1970s gave us “Bicycles for the Mind”.</p>
                            <br />
                            <p>Now is the era of Spaceships for the Mind.</p>
                            <br />
                            <p>Here&apos;s to the really crazy ones.</p>
                        </div>
                        <div className={homeStyles.products}>
                            <div className={homeStyles.product}>
                                <Image
                                    src={'/images/landingPage/home/home-desktop.png'}
                                    width={'171'}
                                    height={'114'}
                                    // layout="intrinsic"
                                    alt=""
                                    loading="lazy"
                                />
                                Web Desktop
                            </div>
                            <div className={homeStyles.product}>
                                <Image
                                    src={'/images/landingPage/home/home-webfileexplorer.png'}
                                    width={'171'}
                                    height={'114'}
                                    // layout="intrinsic"
                                    alt=""
                                    loading="lazy"
                                />
                                Web File Explorer
                            </div>
                            <div className={homeStyles.product}>
                                <Image
                                    src={'/images/landingPage/home/home-aibox.png'}
                                    width={'171'}
                                    height={'114'}
                                    // layout="intrinsic"
                                    alt=""
                                    loading="lazy"
                                />
                                Personal Ai
                            </div>
                        </div>
                        <div className={styles.footer}>
                            <Row>
                                <Col>
                                    <span className={styles.text}>Follow </span>
                                    <a
                                        className={styles.link}
                                        style={{ paddingRight: '5px' }}
                                        href="https://twitter.com/bedrockcomputer"
                                    >
                                        @bedrockcomputer
                                    </a>
                                    <span className={styles.text}> • </span>
                                    <span className={styles.text} style={{ paddingLeft: '5px' }}>
                                        Join{' '}
                                    </span>
                                    <a className={styles.link} href="https://discord.gg/C94bFjya5D">
                                        Bedrock Discord
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className={styles.smallLink}>
                                        <Link href="/terms">Terms of Service</Link>
                                    </span>
                                    <span className={styles.text}> • </span>
                                    <span className={styles.smallLink}>
                                        <Link href="/privacy">Privacy Policy</Link>
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export async function getServerSideProps(context) {
    const session: any = await getSession(context);
    const personEntity = session?.personEntity as Entity;
    const isBaseApp = context.req.headers['user-agent'].includes('BedrockBase');
    const modal = context.query.modal === 'true';
    if (!session) {
        if (isBaseApp) {
            return {
                props: {},
                redirect: {
                    permanent: true,
                    destination: '/signup',
                },
            };
        } else {
            return {
                props: {},
            };
        }
    }

    if (session && personEntity?.complete && !personEntity?.waitlist && !modal) {
        const firstDataspace = Object.keys(session.memberships)[0];
        return {
            props: {},
            redirect: {
                permanent: false,
                destination: '/base/' + firstDataspace,
            },
        };
    }
    return { props: { session } };
}

export default NewLanding;
